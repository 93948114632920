@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');


/* General   */
body {font-family: "Plus Jakarta Sans", sans-serif;background-color:#0F0F0F;}
.headings {font-size: 45px;font-weight: 800;letter-spacing: .5px;line-height: 50px;color:#1b1d1d; text-transform:capitalize; text-align: center;}
.colorORtxt {color:#ef3b13;}
.colorBLtxt {color:#1b1d1d;}
.colorGRtxt {color:#6f7176;}
.colorRDtxt {color:#bb0002;}
.paraText {color:#333;font-size: 18px;font-weight: 500;}
.myBTN {color: #fff;background-color: #333;border-color: #333;border-radius: 4px; padding: 8px 25px;}
.myBTN:hover, .myBTN:focus, .myBTN:active, .myBTN:not(:disabled):not(.disabled):active, .myBTN:not(:disabled):not(.disabled):active:focus {color: #fff;border-color: #333;background-color: #333;outline:none;box-shadow: 0px 0px 4px #333;}
.sectionWrap {padding-top: 60px;padding-bottom: 60px;}
.width100 {width: 100%;}
.displayBlock{display: block;}
.relative{position: relative;}
hr{border-top: 1px solid #fff;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.container {
    width: 90%;
    max-width: 1120px;
}
/* ---- NavBar & Banner ----  */
.topBannerBox {background: url(../../Images/homeimg2/herobg.png);background-repeat: no-repeat; background-position: center;background-size: cover;}
/* Navbar */
.logo_main {height:25px;}
.logo_main.smS {display:none;}
.mainHeader {background-color: transparent;padding: 5px 0;position: sticky; top: 0;z-index: 9999;}
.mainHeader .navbar-nav .nav-link {color: #fff;padding: 3px 20px}
.mainHeader .navbar-nav .userLogin {border-right: 1px solid #fff;}
.mainHeader .navbar-nav .userLogin .nav-link i.fa-user {margin-right:8px;}
.mainHeader .navbar-nav .loginTxt .nav-link {background-image: linear-gradient(90deg, #1a1a1a, #812021, #d23335, #D12F31);color: #fff;margin-left: 20px;border-radius: 30px;padding: 5px 20px;font-weight:500;}
.mainHeader .navbar-nav .nav-link:hover {background-color: #00000054;border-radius:25px;}
.mainHeader .navbar-nav .loginTxt .nav-link:hover {background-color:#d12f31;color:#fff;}
.mainHeader { background-color: transparent;}
.mainHeader.stickyHead { background-color: #fff;z-index:999;background-image: linear-gradient(90deg, #d1d1d1, #dbdbdf, #472a2e, #6d2727);position:fixed;}
.inputSubmit .myBTN:hover{color: #fff;border-color: #333;background-color: #333;outline:none;box-shadow: 0px 0px 4px #333; cursor: pointer;}
.mainHeader .navBG {background-image: linear-gradient(90deg, #d1d1d1, #868686, #868686);padding: 10px 20px;border-radius: 50px;}
.mainHeader.stickyHead .navBG {background-image:none;}
/* Banner */
.bannerTextWrap {padding-top: 50px;padding-bottom: 30px;}
.bannerTextWrap .headings {color: #fff;text-align: center;font-size: 44px;line-height: 80px; font-weight: 500; margin: 0 auto;letter-spacing: 2px;width: 90%;margin: 0 auto;margin-bottom: 20px;text-shadow: 1px 1px 3px #000;}
.bannerTextWrap .paraText {color: #fff;text-align: center;line-height: 24px;font-size: 15px;font-weight: 400;width: 85%; margin: 0 auto;letter-spacing: 1px;}
.inputSubmit {position: relative;}
.inputSubmit {margin-top: 20px;}
.inputSubmit .email_b {height: 55px;border-radius: 40px;padding-left: 45px;}
.inputSubmit .envs {position: absolute;top: 17px;left: 18px; font-size: 22px;}
.inputSubmit .myBTN {position: absolute;right: 5px; top: 4px;background-color: #d12f31;border-color: #d12f31;box-shadow: none;border-radius: 35px;padding: 10px 30px;}
.dashImg {text-align: center;transform: translateY(0px);}

/* ---- Explore Surveycliq ----  */
/*.exploreWrap{background: url(../image/homeImg2/watermark1left.png), url(../image/homeImg2/exploresurveycliq.png);background-repeat: no-repeat; background-position:top left, center;background-size:270px, cover; padding-top:100px; padding-bottom: 230px;}*/
.exploreWrap{background-color:#0E0E0E; padding-top:150px; padding-bottom: 100px;position:relative;z-index:-2;}
.exploreWrap .headings{color:#fff;margin-top: 10px;margin-bottom: 50px}
.exploreWrap .paraText{text-align: center;margin-top: 15px;font-size: 16px; color:#fff;}
.exploreWrap .explrBG{border: 2px solid #707070; padding:30px 40px;}
.exploreWrap .explorIMG{position:relative;}
.exploreWrap .earthimg{position:absolute; height:240px; top:-85px; left:-23px;}
.exploreWrap .rectimg{position:absolute;height: 80px;top: -50px;right: -80px;z-index: -1;}
/* ---- Product Highlights ----  */
.productWrap{background: url(../../Images/homeimg2/prodbg.png);background-repeat: no-repeat; background-size:cover;padding-top: 30px;}
.productWrap .headings{margin-bottom: 50px; text-align:left;color:#fff;}
.productWrap .productBoxMain {max-height: 185px;overflow:hidden;}
.productWrap .productBox {border: 1px solid #c9c9c9;border-radius: 0px;padding: 35px 25px; min-height: 185px;cursor:pointer;transition:all linear .5s;}
.productWrap .productBox .productBoxA i {color:#fff;font-size:30px;}
.productWrap .productBox .productBoxA img {height:30px;}
.productWrap .productBox .productBoxA .paraText{font-size: 20px;font-weight: 700;margin:0; margin-top: 15px;color: #fff;}

.productWrap .productBox .productBoxB {display:none;transition:all linear .5s;}
.productWrap .productBox .productBoxB i {color:#000;font-size:30px;}
.productWrap .productBox .productBoxB img {height:30px;}
.productWrap .productBox .productBoxB .paraText{font-size: 15px;font-weight: 700;margin:0; margin-top: 10px;color: #333;}
.productWrap .productBox .productBoxB .paraTextLow {font-size: 12px;font-weight: 500;margin:0; margin-top: 5px;color: #535353;}

.productWrap .productBox:hover { background-image: linear-gradient(180deg, #ffffff, #ffd2c8); border-color: #fff3f1;padding: 20px 25px;transition:all linear .5s;}
.productWrap .productBox:hover .productBoxA {display:none;transition:all linear .5s;}
.productWrap .productBox:hover .productBoxB {display:block;transition:all linear .5s;}

/* Book-Demo */
/*.productWrap .bookdemo{background-image: linear-gradient(90deg, #1B1D1D, #1B1D1D, #BB0002, #BB0002);padding: 40px 70px; border-radius: 30px;box-shadow: 2px 2px 10px #cbcaca; margin-top: 60px;position: relative; }
.productWrap .bookdemo .envm{position: absolute;bottom: 50px;left: 85px;font-size: 22px;color: #fff;}
.productWrap .bookdemo .email_b{height: 45px;border-radius: 40px;padding-left: 45px;background-color: #9b6b6d;color:#fff; border-color: #9b6b6d;}
.productWrap .bookdemo .email_b::placeholder {color: #fff;}
.productWrap .bookimg{position: absolute; left: 145px;z-index: 1;}
.productWrap .heading{font-size:35px;font-weight: 700;text-align: center;margin-bottom: 20px;color: #fff;}*/

/* ---- What motivates us to do this? ----  */
.motivateWrap{background-color:#0F0F0F;}
.motivateWrap .headings{margin-top:25px;margin-bottom: 25px;text-transform: none; color:#fff; text-align:left;}
.motivateWrap .colorRDtxt{color:#D12F31; font-size:60px;margin-top:5px;margin-bottom:5px;}
.motivateWrap .paraText{color:#fff;font-size:16px;}


.ourfeatureWrap .ourBG{background-color:#929698; border:1px solid #fff; border-radius:10px;margin-bottom:50px;}
.ourfeatureWrap .headings{margin-bottom: 10px; text-align:left;color:#fff;font-size:35px;font-weight:600;}
.ourfeatureWrap .paraText{font-size:14px;line-height:25px;letter-spacing:.2px; color:#fff;}
.ourfeatureWrap .featureimg{margin-top: 20px;}
.ourfeatureWrap .motivBG{position:absolute;bottom:0;}
.ourfeatureWrap .headBox {padding: 20px 10px 0px 10px;}
.ourfeatureWrap .featureimg img{width:100%;}

.fa{
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.footerWrap .socialWrap a {
    background-color: #fff;
    margin-right: 15px;
    font-size: 30px;
    text-align: center;
    display: inline-block;
    box-shadow: 1px 1px 5px #d5d5d5;
    border-radius: 3px;
}
.contactWrap{}
.contactWrap .contactHead {background-image: linear-gradient(90deg, #3c3c3c, #343333, #343434, #7f2122);
        padding: 35px 30px;border-radius: 30px;}
.contactWrap .imgHead{height: 80px; width: 80px; border-radius: 50%; background-color: #fff;padding: 14px;}
.contactWrap .favicon{height: 50px;}
.contactWrap .socialWrap{margin-top: 100px;}
.contactWrap .socialWrap a {text-decoration: none;margin-right: 5px;border: 1px solid #727272;border-radius: 6px;font-size: 18px;padding: 6px 10px;color: #fff;min-width: 40px;display: inline-block;text-align: center;}
.contactWrap .socialWrap a:hover {background-color: #fff;color: #000;border-color: #fff;}
.contactWrap .heading{ font-size: 28px;color: #fff;margin-top: 15px;font-style: italic;}
.contactWrap .heading1{font-size: 35px; color: #fff; font-weight: 700;margin-top: 20px;margin-bottom: 20px;}
.contactWrap .form-control{background-color:transparent; border: 1px solid #c9c9c9;margin-bottom:22px;}
.contactWrap .myBTN{background-color: #fff;border-color: #fff; color:#000;border-radius: 35px;padding: 10px 30px;}
.contactWrap .myBTN:hover{color: #fff;border-color: #333;background-color: #333;outline:none;box-shadow: 0px 0px 4px #333; cursor: pointer;}
 .contactWrap .reBG{background-image: linear-gradient(90deg,#0f0f0f,#7f2122, #7f2122);border-radius: 30px;}
 .contactWrap ::placeholder{color:#fff;}
/* Footer */
.footerWrap {background-color: #595959;}
.footerWrap .logo_main{height: 20px; margin-bottom: 40px;}
.footerWrap{padding-bottom:10px;padding-top: 40px;}
.footerWrap p{display:flex;color:#fff;}
.footerWrap i{font-size:20px;margin-right:15px;font-weight: 600;margin-top: 4px;min-width:20px; color:#fff;}
.footerWrap .fa-map-marker-alt{font-size:20px; color:#fff;}
.footerWrap a{font-size:16px;text-decoration:none;transition:all linear .5s; color:#fff;}
.footerWrap a:hover {margin-left:5px;color:#fff;;}
.footerWrap .footerHeading{
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;}

    /* .footerWrap .footerlinks a{
        font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    } */

    .footerWrap .footerlinks a{
        font-size: 15px;
        font-weight: 500;
        color: #6f7176;
    }



/* ---------- Media ---------- */
/* ##Device = Desktops, ##Screen = 1281px to higher resolution desktops */
@media (min-width:1281px){
   }
   /* ##Device = Laptops, Desktops, ##Screen = B/w 1025px to 1280px */
   @media (min-width:1025px) and (max-width:1280px){
   }
   /* ##Device = Tablets, Ipads (portrait), ##Screen = B/w 768px to 1024px */
   @media (min-width:768px) and (max-width:1024px){
    .profile-user {margin-top:8px;}
    /* .leftsideImg{display:none;} */
   }
   /* ##Device = Tablets, Ipads (landscape), ##Screen = B/w 768px to 1024px */
   @media (min-width:768px) and (max-width:1024px) and (orientation:landscape){
   }
   /* ##Device = Low Resolution Tablets, Mobiles (Landscape), ##Screen = B/w 481px to 767px */
   @media (min-width:481px) and (max-width:767px){
    .profile-user {margin-top:8px;}
    .leftsideImg{display:none;}
   }
   /* ##Device = Most of the Smartphones Mobiles (Portrait), ##Screen = B/w 320px to 479px */
  @media (min-width:320px) and (max-width:480px){
  .container {width:90%;}
  .mainHeader .navBG{background-image:none;border-radius:0;}
  .exploreWrap .explorIMG{display:none;}
  .exploreWrap .explrBG{padding:0;}
  .productWrap .headings{margin-top:30px; margin-bottom:30px;}
  .motivateWrap .colorRDtxt {
      color: #D12F31;
      font-size: 35px;
      margin-top: 0px;
      margin-bottom: 0px;
  }
  .ourfeatureWrap .motivBG{position:static;}
  .motivateWrap .headings{text-align:center;}
  .mainNavbar .navbar {
      padding: 4px 0px;
  }
  .paraText {font-size: 16px;}
  .logoMain {height: 25px;}
  .mainNavbar .navbar-toggler {padding: 5px 10px;font-size: 16px;line-height: 1;background-color: #fff;border: 1px solid #ef3b13;}
  .headings {font-size:30px;line-height: 38px;}
  .bannerContent .headings {font-size:28px; text-align:left;}
  .bannerWrap .bannerLeft {padding-top:0;margin-bottom: 40px;}
  .stickyHead + .bannerContent {padding-top:0;padding-bottom: 320px;}
  .bannerContent {padding-top:100px;padding-bottom: 320px;}
  .bannerWrap {margin-top: 0px;background-image: linear-gradient(5deg,#ffffff,#ffbdaf,#ff947d,#b76d5c);}
  .sectionWrap {padding-top:50px;padding-bottom:30px;}
  .opinionBoxs { margin-bottom: 100px;box-shadow: 0px 0px 18px #c5c5c5;}
  .opinionBoxPara {font-size:14px;}
  .opinionLaptop {margin-top: 0;margin-bottom: 30px;}
  .motivateLeft {margin-top: 0;}
  .featuresAll {height: auto;}
  .bannerWrap .bannerBGImg {display:none;}
  .mainNavbar {background: #fff; position: fixed;width: 100%; left: 0; top: 0; z-index: 99;}
  .mainNavbar.stickyHead {background-image:none;}
  .footerWrap {padding-bottom: 5px;text-align: center;}
  .footerWrap .logoMain {height:25px;margin:0 auto;}
  .footerWrap .socialWrap a {background-color: #fff;
    margin-right: 15px;
    font-size: 30px;
    text-align: center;
    display: inline-block;
}
  .footerWrap .socialWrap a i {padding: 0 !important;}

  .topBannerBox {background-position:right;}
  .mainHeader.stickyHead {background-image: none;background-color: #212121;}
  .logo_main.lgS {display:none;}
  .logo_main.smS {display:inline-block;height:22px;margin-top: -2px;}
  .mainHeader .navbar {padding:1px 0;}
  .mainHeader .navbar .navbar-toggler {background-color:#fff;}
  .bannerTextWrap .headings {font-size:24px;line-height:35px;width:100%;margin-bottom:10px;}
  .bannerTextWrap .paraText { line-height: 20px;font-size: 14px;font-weight: 500;width: 100%;}
  .inputSubmit {margin-top:30px;}
  .inputSubmit .myBTN {position: static;display: block;margin: 0 auto; margin-top: 10px;border-color: #8b0002;}
  .dashImg {transform: translateY(1px);}
  .exploreWrap {padding-top: 50px;padding-bottom: 100px;background-position: top left, left;}
  .productWrap {padding-top:0;}
  .productWrap .productBoxMain {margin-bottom:30px;}
  .productWrap .bookimg {position:static;}
  .productWrap .bookdemo {margin:0;padding: 30px 20px;}
  .productWrap .bookdemo .email_b {padding-left:20px;}
  .productWrap .bookdemo .envm {position:static;display:none;}
  .motivateWrap {padding-top: 10px;padding-bottom: 10px;text-align:center;}
  .motivateWrap .headings {margin:0;}
  .ourfeatureWrap .headings { margin-bottom: 20px;}
  .contactWrap .heading {font-size:24px;}
  .contactWrap .socialWrap {margin-top: 30px;}
  .contactWrap .myBTN {width:100%;}
  .footerWrap {text-align:left;}

  .signinBGs {
      background: url(../../Images/signBgwater.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  .leftsideImg {display:none;}
  .formBG a.navbar-brand img {height:24px !important;}
  .formSignBgs .formSignBox {padding:5px;background: none;width: 100%;}
  .socialLogIn a, .socialLogIn a:hover {padding:7px 0;}
  .motive-img {position:static;}
  .our-feaaturebg {top:0;padding-top:50px;padding-bottom: 50px;}
  .contactWrap .contact-img {position:static;}
  .contactWrap .headings {top: 45%;right: initial;left: 0;font-size: 30px;width: 100%;}
  .contactWrap {top:-2px;padding-top:5px;}
  
  }



