
/* ---- FONTS ---- */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');/* goopoll css */

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700|Nunito:400,600,700&display=swap");a{text-decoration:none;outline:none}
body {
	font-family: "Nunito",sans-serif;
	color: #fff;
	background: #fff;
}

/*signup*/


.forms {
	background: #626262;
	/*box-shadow: 0px 5px 20px #090e23;*/
	margin: 30px auto;
	max-width: 500px;
	overflow: hidden;
	position: relative;
	padding: 0;
	border-radius: 10px;
}
.forms h1 {
	padding: 0 0 20px 0;
	font-size: 26px;
	color: #666;
	font-weight: lighter;
	text-align: center;
}
.forms form { 
    padding: 0 30px;
    padding-bottom: 15px;
 }

.forms .tab-group {
	list-style: none;
	padding: 0;
	margin: 0;
}
.forms .tab-group:after {
	content: "";
	display: table;
	clear: both;
}
.forms .tab-group li a {
	display: block;
	text-decoration: none;
	padding: 15px;
	background: #e5e6e7;
	color: #888;
	font-size: 20px;
	float: left;
	width: 50%;
	text-align: center;
	border-top: 3px solid transparent;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
/* .forms .tab-group li a:hover {
	background: #dedfdf;
	color: #666;
}*/
.forms .tab-group .active a {
	background: #fff;
	color: #444;
	border-top: 3px solid #73cf41;
}
	.forms input, .forms select {
		font-size: 16px;
		display: block;
		width: 100%;
		padding: 10px 20px;
		border: 1px solid #ddd;
		color: #666;
		border-radius: 10px;
		margin-bottom: 0px;
		-moz-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		-webkit-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		height: auto;
	}
		.forms select {
			line-height: 1;
		}
		.forms input:focus, .forms select:focus {
			outline: 0;
			border-color: #2e5ed7;
		}
.forms label {
	font-size: 16px;
	font-weight: normal;
	/*color: #666;*/
	margin-bottom: 5px;
	display: block;
}

.form-group{
    border-radius: 10px;
}

	.forms input:focus, .forms select:focus {
		border: 1px solid #333 !important;
		background: #fff !important;
		color: #000;
	} .hm-vctr{margin-top: -60px; margin-bottom:0px;}
.bnr-pra{margin-top: -30px;line-height: 28px;} .mrgn-tpbnr{margin-top:-34px!important;}

.hero-4-bg .hero-login-form{    margin-top: -71px !important;  top: 15px;} .bnr-pra{display:none;}
.hero-4-bg{padding-bottom:10px!important;} .hero-4-bg {  background-size: cover !important; background-repeat:no-repeat!important;} 
#features{
	background:#fff;	
	/* background: url(); */
	background-repeat:no-repeat!important; background-size:cover!important;}.btn.btn-primary{border-radius: 30px;background: #7fc380 !important;border-color:#7fc380 !important;box-shadow:none!important;box-shadow: 0px 7px 20px #c8c8c8 !important;}
.btn.btn-primary:hover{border: 1px solid #7fc380 !important;background: #5bb05c!important;box-shadow:none!important;transform: none!important;box-shadow: 0px 7px 20px #c8c8c8 !important;}
.mail a{color:#000!important;} .mail a i{color: #7fc380;}
.form-group { margin-bottom: 15px;}

.btn-social.btn-google:hover {
	color: #0056b3;
	transition: none;
	transform: none;
}
#signup .forms {
	padding: 20px 30px;
	border-radius: 5px;
	margin-top: 70px;
	margin-bottom: 0;
}
.Signupform {
	background: #fff;
	box-shadow: 0px 5px 20px #3e3e3e54;
	position: relative;
}
.btn-social.btn-google {
	color: #007bff;
	font-weight: normal;
}
#signup .form-group {
	/*margin-bottom: 25px;*/
	margin-bottom: 15px;
}
#signup .forms h1 {
	text-align: left;
	font-weight: 700;
	font-size: 20px;
}
.navbar-brand {
	padding: 0;
}
#signup .forms {
	min-width: 397px;
}

.signup-wrapper {
	/* background: url() center center; */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 100% 40px;
}

span.field-validation-error {
	font-size: 12px;
}

/* input[type=submit]:focus {
	background: #4ea84f !important;
	border-color: transparent !important;
} */

.form-group {
	position: relative;
}
.field-icon {
	position: absolute;
	right: 25px;
	top: 43px;
	z-index: 2;
	color: #333;
}
#signup .field-icon {
	right: 15px;
	top: 15px;
}
.fa-fw {
	width: 1.28571429em;
	text-align: center;
}

@media (max-width: 1200px) {
	.hero-4-bg {
		background-position: 42% 15% !important;
	}
	.hm-ttl {
		font-size: 35px;
	}
}
@media (max-width: 992px) {
	.ftr-lftspc,
	.cmpnylft-spc {
		padding-left: 0
	}
}
@media (max-width: 767.9px) {
	.section {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.hm-ttl {
		line-height: 45px;
		font-size: 26px;
	}
	.service-img {
		display: none;
	}
	
}

/*selectmenu css*/
.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
	width: 100% !important;
}
.ui-selectmenu-open {
	z-index: 1 !important;
}
.ui-front {
	z-index: 999999 !important;
}

.ui-selectmenu-button.ui-button {
	/*display: block;
	width: 100% !important;
	height: 34px;
	padding: 7px 12px;
	color: #8e8e93;
	line-height: 20px !important;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #c7c7cc;
	border-radius: 4px !important;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
	font-size: 16px;
	display: block;
	width: 100%;
	padding: 10px 20px;
	border: 1px solid #ddd;
	color: #666;
	border-radius: 0;
	margin-bottom: 0px;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	line-height: 2;
	height: 44px;
	padding: 10px 10px 10px 20px;
	border-radius: 12px;
	border: 1px solid #7fc380 !important;
}

.ui-selectmenu-text {
	font-size: 13px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	letter-spacing: 0;
}

.ui-selectmenu-button.ui-button.ui-widget.ui-selectmenu-button-open.ui-corner-top {
	/*border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none;*/
}
.ui-icon-triangle-1-s {
	background-position: -65px -12px;
}
.ui-menu .ui-menu-item-wrapper {
	padding: 3px 12px !important;
}

.ui-widget.ui-widget-content {
	color: #8e8e93;
	line-height: 20px !important;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #c7c7cc;
	border-radius: 4px !important;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	font-size: 13px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
	letter-spacing: 0;
	font-weight: normal;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	max-height: 288px;
	max-width: 1095px;
	max-height: 185px;
}

.ui-button:active, .ui-button:hover {
	background: #ffffff !important;
	color: #8e8e93 !important;
}
.ui-button:focus {
	border-color: #328833 !important;
}
.ui-state-active .ui-icon, .ui-button:active .ui-icon {
	/* background-image: url(); */
}
*:focus {
	outline: none;
}

#PollResult {
	position: relative;
	padding: 85px 0 20px;
}
#PollResult .banner-text {
	margin: 11% 0 0%;
}
#pollChart .boxheader {
	background-color: #80c481;
	color: #fff;
}
#PollResult .heading-title {
	margin-bottom: 20px;
}

#pollChart .boxheader {
	font-size: 16px;
	padding: 8px 10px;
	font-weight: 700;
}

#PollResult .heading-title .divider {
	margin-top: 20px;
}

#PollResult .highcharts-description b {
	font-weight: normal;
	font-size: 24px;
	color: #000;
}

#PollResult .highcharts-description {
	font-size: 0;
	margin-left: 0;
	margin-bottom: 10px;
}

.card-title {
	margin-bottom: 0;
}
#Faq .title {
	font-size: 20px;
}
#Faq h4.card-title {
	font-size: 16px;
}
#Faq .card-body {
	font-size: 16px;
}


input:-webkit-autofill {
	background-color: #fff !important;
	background-image: none !important;
}