.container {
    width: 100%;
    max-width: 400px;
  }


  
  .card {
    width: 100%;
    background-color: #707070; /* Warna card */
    padding: 20px;
    border-radius: 12px; /* Border radius card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    /* padding: 10px; */
    /* margin-bottom: 12px; */
    border: 2px solid #ddd; /* Border color input */
    border-radius: 8px; /* Border radius input */
    transition: border-color 0.3s ease-in-out;
    outline: none;
    color: #333;
    background-color: #f4f4f4; /* Warna input */
  }
  
  input:focus {
    border-color: #ff9900; /* Warna input saat focus */
  }
  
  button {
    background-color: #ff9900; /* Warna button */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 8px; /* Border radius button */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  button:hover {
    background-color: #ff6600; /* Warna button saat hover */
  }


  /* new style  */
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
  
.ddOutOfVersion{
    height: 0px;
    overflow: hidden;
    position: absolute;

}

select {
  word-wrap: normal;
}
select option {
  background: #121722;
  color: #fff;
}
.grpAddon .dd {
  border: none;
  width: 100px !important;
  text-align: left;
  border-radius: 0;
  border: none;
}
.grpAddon .dd .ddTitle {
  background: #605a59;
  border: none;
  height: 38px;
  padding: 6px;
  padding-left: 25px;
  border-bottom: 1px solid #605a59;
  border-radius: 0;
  box-shadow: none !important;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.grpAddon .dd .divider {
  border: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  /* border: 1px solid #cacaca; */
  /* border-radius: 3px 0 0 3px; */
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
.react-tel-input .country-list{
  background-color: #707070 !important;
}
/* body .react-tel-input .form-control{
  margin-top: -3px !important;
} */
.react-tel-input .country-list ul li:hover{
  background-color: #707070 !important;
}
.react-tel-input .country-list .country .dial-code {
  color: #ffffff !important;
}

.react-tel-input .country-list .country:hover{
  background-color: #795858 !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #795858 !important;
  color: black !important;
}
.react-tel-input .country.highlight .country {
  color: black !important;
}

.react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent !important;
}

.react-tel-input .flag-dropdown.open{
  background: transparent !important;
}

.react-tel-input .selected-flag .arrow{
  border-top: 4px solid #fff !important;
}



.dd .ddArrow {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background: url("https://www.marghoobsuleman.com/assets/mywork/jcomponents/image-dropdown/samples/images/msdropdown/skin1/dd_arrow.gif") no-repeat;
}