.outer{
    border: 1px solid #3c3c3c;
    padding: 7px;
    border-radius: 20px;
    justify-content: space-between;
}


.userDashboard{
    background-color: black;
    /* width: 804px; */
    position: relative;
    /* height: 570px; */
    height: 540px;
    border-radius: 20px;
    /* padding: 20px 0px; */
}


.Heading{
    font-size: 20px;
    margin-bottom: 5px;
}
.descreption{
    font-size: 14px;
}
.justify-content{
    justify-content: space-between;
}
.feedScroll{
    /* background-color: lightblue; */
  height: 500px;
  /* width: 200px; */
  overflow-y: scroll;
  overflow-x: hidden;
}
.feedScroll::-webkit-scrollbar {
    width: .2em;
  }
   
.feedScroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
.feedScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(92, 90, 90);
  }

  .rightside{
    display: block;
    width: 24%;
  }

  .leftSide{
    width: 75%;
  }

  @media (max-width: 1306px) {
    .rightside {
      /* display: none; Hide the right side on smaller screens */
      width: 32% ;
    }
  
    .feedscrool {
      flex: 1 1 100%; /* Make feedscrool take full width */
    }

    .leftSide{
      width: 67%;
    }
  }


  @media (max-width: 829px) {
    .rightside {
      display: none; /* Hide the right side on smaller screens */
      /* width: 32% ; */
    }
  
    .feedscrool {
      flex: 1 1 100%; /* Make feedscrool take full width */
    }

    .leftSide{
      width: 100%;
    }
  }
  