
@keyframes scale {
    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@keyframes box {
    0% {
        top: 0;
        left: 0px;
    }

    50% {
        top: 0;
        left: 80px;
    }
}

.bannerWrap {}
/* ---- Signing Pages ---- */
.signinBGs {background: url(../../Images/internalbg.jpg);background-size: cover;height:100vh;}
.signinBGs.signinBG2 {background: url(../../Images/signBgwater.png);background-size: cover;height:100vh;}
.rightWraps, .formBG {background-image:none;}
.my-ftr {display:none;}
.page-section {padding: 0;}
.formSignBgs {position:relative;}
.formSignBgs .formSignBox {/*background: url(/image/signBgwater.png);*/background-size: cover; background-position: center; background-color:#7e7e7e36;padding:40px 50px;position:absolute;top:50%;left:45%;transform:translate(-50%, -50%); width: 85%; border-radius: 35px;
    /* backdrop-filter: blur(30px); */
}
.formSignBgs .formSignBox.signupBox {padding:10px 50px 20px;backdrop-filter: blur(30px);}
.formSignBgs .formSignBox.actCode {background-repeat:no-repeat;background-size:contain;}
.formSignBgs .formSignBox.actCode.prflSurvey {left:50%; width: 80%;}
.formSignBgs .formSignUP .formSignBox {padding:10px 50px;}
.signinBGs .formBG .pf-form__textfield__input {background: #70707099 !important;border: none; padding-left: 12px; color: #fff !important;/*box-shadow: 0px 3px 5px #bfbfbf;*/font-size:15px;border-radius:30px;}
.leftsideImg { position: absolute;top: 50%;left: 55%;transform: translate(-50%, -50%);text-align: center;width: 75%;z-index: 99;}
.activationimg{width:80%;}
.lgnbtn {min-height:40px;border-radius:30px;background-color: transparent;border: 1px solid #fff;}
.lgnbtn:hover {background-color: #000;border: 1px solid black; color: #fff;box-shadow: 1px 1px 8px #484848;}
.socialLogIn a, .socialLogIn a:hover {border-radius:5px;margin:2px 5px;width:45%;font-weight: 500;}
.formBG .pf-form__textfield label, .formBG .form-check label {margin-top: 0;}
#contact{color:#fff; text-align:center}
#contactMSG {display:none}
select option {background:#121722;color: #fff;}
.mobileLogo {display:none;}


/*Admin login*/
.adminLogin {
    background-image: url(../../Images/bgH.jpg);
    /*background-image: url(/image/bgH_Dark.jpg);*/
    background-repeat: no-repeat,no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    height: 100vh;
}
.adminLogin .forms {background-color:#333 !important;}
.adminLogin .forms .button {font-weight:500;transition:none; border-radius: 7px;padding: 8px 0;font-size: 16px; letter-spacing: 1px;    background: #0162e8;}
.adminLogin .forms .button:hover {box-shadow: 1px 1px 8px #484848;transition:none; background: #0162e8;}
.adminLogin .forms input, .adminLogin .forms select {padding:8px 20px;font-size:15px;}
.adminLogin #formContent .text-danger.validation-summary-errors ul {padding:0 !important;margin:0;position:relative;}
.adminLogin #formContent .text-danger.validation-summary-errors ul li {list-style:none !important;font-size: 13px; position: absolute; top: -15px;}
.adminLogin span.field-validation-error {position:absolute;}


/* ---------- Media ---------- */
/* ##Device = Desktops, ##Screen = 1281px to higher resolution desktops */
@media (min-width:1281px){
   }
   /* ##Device = Laptops, Desktops, ##Screen = B/w 1025px to 1280px */
   @media (min-width:1025px) and (max-width:1280px){
   }
   /* ##Device = Tablets, Ipads (portrait), ##Screen = B/w 768px to 1024px */
   @media (min-width:768px) and (max-width:1024px){
    .profile-user {margin-top:8px;}
    .leftsideImg{display:none;}
   }
   /* ##Device = Tablets, Ipads (landscape), ##Screen = B/w 768px to 1024px */
   @media (min-width:768px) and (max-width:1024px) and (orientation:landscape){
   }
   /* ##Device = Low Resolution Tablets, Mobiles (Landscape), ##Screen = B/w 481px to 767px */
   @media (min-width:481px) and (max-width:767px){
    .profile-user {margin-top:8px;}
    .leftsideImg{display:none;}
   }
   /* ##Device = Most of the Smartphones Mobiles (Portrait), ##Screen = B/w 320px to 479px */
   @media (min-width:320px) and (max-width:480px){
    .mobileLogo {display:block;}
    .container {width:90%;}
    .mainNavbar .navbar {padding: 4px 0px;}
    .paraText {font-size: 16px;}
    .logoMain {height: 25px;}
    .mainNavbar .navbar-toggler {padding: 5px 10px;font-size: 16px;line-height: 1;background-color: #fff;border: 1px solid #ef3b13;}
    .headings {font-size:30px;line-height: 38px;}
    .bannerContent .headings {font-size:28px; text-align:left;}
    .bannerWrap .bannerLeft {padding-top:0;margin-bottom: 40px;}
    .stickyHead + .bannerContent {padding-top:0;padding-bottom: 320px;}
    .bannerContent {padding-top:100px;padding-bottom: 320px;}
    .bannerWrap {margin-top: 0px;background-image: linear-gradient(5deg,#ffffff,#ffbdaf,#ff947d,#b76d5c);}
    .sectionWrap {padding-top:50px;padding-bottom:30px;}
    .opinionBoxs { margin-bottom: 100px;box-shadow: 0px 0px 18px #c5c5c5;}
    .opinionBoxPara {font-size:14px;}
    .opinionLaptop {margin-top: 0;margin-bottom: 30px;}
    .motivateLeft {margin-top: 0;}
    .featuresAll {height: auto;}
    .bannerWrap .bannerBGImg {display:none;}
    .mainNavbar {background: #fff; position: fixed;width: 100%; left: 0; top: 0; z-index: 99;}
    .mainNavbar.stickyHead {background-image:none;}
    .footerWrap {padding-bottom: 5px;text-align: center;}
    .footerWrap .logoMain {height:25px;margin:0 auto;}
    .footerWrap .socialWrap a {background:transparent;box-shadow: none;font-size: 24px; }
    .footerWrap .socialWrap a i {padding: 0 !important;}

    .signinBGs {background: url(../../Images/signBgwater.png);background-size: cover; background-repeat: no-repeat; background-position: center;}
    .leftsideImg {display:none;}
    .formBG a.navbar-brand img {height:24px !important;}
    .formSignBgs .formSignBox {padding:5px;background: none;width: 100%;left:50%;}
    .formSignBgs .formSignBox.signupBox {padding:0;}
    .socialLogIn a, .socialLogIn a:hover {padding:7px 0;}
    .motive-img {position:static;}
    .our-feaaturebg {top:0;padding-top:50px;padding-bottom: 50px;}
    .contactWrap .contact-img {position:static;}
    .contactWrap .headings {top: 45%;right: initial;left: 0;font-size: 30px;width: 100%;}
    .contactWrap {top:-2px;padding-top:5px;}
    .frmlgn .main-panel__title{font-size:20px!important;}
    #tester h2{font-size:18px!important;}
    }
   






