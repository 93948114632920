@charset "UTF-8";
/*!
* Start Bootstrap - Agency v6.0.3 (https://startbootstrap.com/theme/agency)
* Copyright 2013-2020 Start Bootstrap
* Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-agency/blob/master/LICENSE)
*/
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap'); */

:root{
    --bg-color: #181818;
}

p{
    margin-bottom: 7.5px;
}

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}



.cursor{
    cursor: pointer;
}

.grid-container {
  display: grid;
  font-family: 'Segoe UI', Tahoma, Verdana, sans-serif ,'Plus Jakarta Sans, Regular';
  grid-template-columns: 235px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 4fr;
  grid-template-areas:
    'header header header header'
    'sidebar main main main';
  height: 100vh;
}

.active {
    border: 1px solid #ffffff;
    border-radius: 10px;
    /* padding: 9px;
    width: 95%;
    margin: 3px; */
}

/* Header  */
.header {
  grid-area: header;
  background-color: var(--bg-color);
  height: 50px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 0 30px;
  /* box-shadow: 0 6px 7px -3px rgb(0, 0, 0); */
}

.scroll-container {
    max-height: 400px;
    overflow-y: scroll;
}

.dropdown-item{
    color:#ffffff;
}
.dropdown-item:hover{
    background-color:#1b1d1d ;
    color:#ffffff;
}

p {
    line-height: 1.75;
}

.dropdown-menu{
    background-color: #1b1d1d;
    color:#ffffff;
    /* margin-top: 3px; */
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

/* For Firefox */
.scroll-container {
    scrollbar-width: none; /* Firefox */
}

/* For Internet Explorer and Edge */
.scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
}

.menu-icon {
  display: none;
}
/* End Header  */

/* Sidebar  */
#sidebar {
  grid-area: sidebar;
  z-index: 2;
  height: 100%;
  background-color: var(--bg-color);
  color:rgb(255, 255, 255);
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  /* box-shadow: 0 6px 7px -3px rgb(61, 64, 70); */
}



.text-decoration{
  text-decoration:none;
  color:#ffffff;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 30px;
  margin-bottom: 40px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  /* margin-top: 10px; */
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
    height: 55vh;
    padding: 0;
    list-style-type: none;
}



.sidebar-list-item {
  padding:10px;
  font-size: 13px;
}

.sidebar-list-item:hover {
  /* background-color: rgba(235, 108, 108, 0.2); */
  cursor: pointer;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #ffffff;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  /*
    we want the z-index of the sidebar higher so that
    the charts are not showing over the sidebar 
    on small screens
  */
  z-index: 12 !important;
}

/* End Sidebar  */


/* Main  */  
.main-container {
  grid-area: main;
  background-color:var(--bg-color) ;
  overflow-y: auto;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.topbar-hover:hover{
    background-color: #1b1d1d;
}

.section-blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(10px);
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.userDashboard {
    /* background: black; */
    /* background: url(https://single.surveycliq.com/image/internalbg.jpg); */
    width: 100%;
    /* height: 100vh; */
    overflow: hidden;
    position: relative;
  }
  .userDashboard:after {
    /* content: ""; */
    width: 100%;
    height: 100vh;
    background: url("https://i.imgur.com/PsjPzdO.png");
    background-size: 200px;
    mix-blend-mode: overlay;
    position: absolute;
    top: 0;
    left: 0;
  }
  .userDashboard .blobs {
    width: 50vh;
    height: 50vh;
    /* display: block; */
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .userDashboard .blobs .blob {
    width: 100%;
    z-index: -1111;
    /* height: 100%; */
    border-radius: 100%;
    /* position: absolute; */
    background-color: #FF4D00;
    mix-blend-mode: multiply;
    filter: blur(80px);
  }
  .userDashboard .blobs .blob.a {
    background-color: #ff7133;
    -webkit-animation: circular2 40s linear infinite;
            animation: circular2 40s linear infinite;
  }
  .userDashboard .blobs .blob.b {
    background-color: #ffa680;
    -webkit-animation: circular 20s linear infinite;
            animation: circular 20s linear infinite;
  }
  .userDashboard .blobs .blob.c {
    -webkit-animation: circular 60s linear infinite;
            animation: circular 60s linear infinite;
  }
  
  @-webkit-keyframes circular {
    0% {
      transform: translate(0, -150px);
    }
    25% {
      transform: translate(150px, 0);
    }
    50% {
      transform: translate(0, 150px);
    }
    75% {
      transform: translate(-150px, 0);
    }
    100% {
      transform: translate(0, -150px);
    }
  }
  
  @keyframes circular {
    0% {
      transform: translate(0, -150px);
    }
    25% {
      transform: translate(150px, 0);
    }
    50% {
      transform: translate(0, 150px);
    }
    75% {
      transform: translate(-150px, 0);
    }
    100% {
      transform: translate(0, -150px);
    }
  }
  @-webkit-keyframes circular2 {
    0% {
      transform: translate(0, 150px);
    }
    25% {
      transform: translate(-150px, 0);
    }
    50% {
      transform: translate(0, -150px);
    }
    75% {
      transform: translate(150px, 0);
    }
    100% {
      transform: translate(0, 150px);
    }
  }
  @keyframes circular2 {
    0% {
      transform: translate(0, 150px);
    }
    25% {
      transform: translate(-150px, 0);
    }
    50% {
      transform: translate(0, -150px);
    }
    75% {
      transform: translate(150px, 0);
    }
    100% {
      transform: translate(0, 150px);
    }
  }
 

/* End Main  */



/* Medium <= 992px */
  
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: -0.8fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    position: relative;
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}


@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-md-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 35.666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.3333333333%;
    }

    .offset-md-2 {
        margin-left: 16.6666666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.3333333333%;
    }

    .offset-md-5 {
        margin-left: 41.6666666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.3333333333%;
    }

    .offset-md-8 {
        margin-left: 66.6666666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.3333333333%;
    }

    .offset-md-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-lg-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 48%;
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }
}
#intrdc .text-muted {
    padding-left: 60px;
    font-size: 16px;
}

#faqsctn {
    background: #044b7c;
    /* background-image: url(''); */
    height: 370px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 7rem;
}

    #faqsctn .section-heading {
        font-size: 3.1rem;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.4px;
    }

    #faqsctn .btn1, #faqsctn .btn2 {
        font-size: 20px;
        font-weight: 500;
        padding: 12px 40px;
        width: 225px
    }

    #faqsctn .btn1 {
        background: #fff;
        color: #044b7c;
        border-color: #fff;
    }

    #faqsctn .btn2 {
        background: transparent;
        color: #fff;
        border-color: #fff;
    }

.footer .content img {
    margin-bottom: 15px;
    background-color: white;
    padding: 5px;
}

.footer .content .social {
    margin-left: 0px;
    padding-left: 0px;
    list-style: none;
    color: #000;
}

    .footer .content .social a {
        color: #fff;
        font-size: 16px;
        text-decoration: none;
    }

.footer .content .social {
    line-height: 30px;
}

    .footer .content .social li {
        padding: 6px 0px;
    }

.fb {
    color: #fff;
    background: #3B5998;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    padding: 1px 0px;
    margin-right: 12px;
}

.twtr {
    background: #08a0e9
}

.instaa {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}


.linkedinclr {
    background: #0e76a8;
    
}


.react-tel-input .form-control{
  
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    margin-top: -5px !important;

}


.ftrlnks ul {
    padding-left: 0px;
    margin-left: 0px;
    list-style: none;
    margin-top: 5px;
}

.ftrlnks .content h5 {
    font-size: 1.2rem;
    margin-top: 45px;
    color: #d8d8d8;
}

.ftrlnks ul li {
    padding: 10px 0px;
}

    .ftrlnks ul li a {
        color: #ffffff;
        font-size: 14px;
        text-decoration: none;
    }

.getstart {
    background: #333;
    border-color: #333;
    border-radius: 50px;
    color: #fff !important;
    padding: 6px 18px !important;
    /* box-shadow: 2px 9px 13px #3359003d; */
}

.my-ftr {
    padding: 0px 20px;
    /*background: #f7f7f7;*/
    background-image: none;
    z-index: 999;
    position: relative;
    bottom: 0px;
    max-width: 100%;
    color: #fff !important;
    position: fixed;
    width: 100%;
}


    .my-ftr .social li {
        margin: 0px 10px;
        display: inline-block;
    }

        .my-ftr .social li a {
            text-align: center;
            color: #333 !important;
            display: inline-block;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            vertical-align: middle;
            font-size: 14px;
        }

    .my-ftr .social {
        /*width: 30%;*/
        margin-bottom: 0px;
        float: right;
        margin-left: 0px;
        padding-left: 0px;
    }





.linksbotm {text-align: center;}

    .linksbotm a {
        color: #1b1d1d !important;
        margin-left: 50px;
        font-size: 14px;
    }

.my-ftr p {
    color: #1b1d1d;
    margin-bottom: 0px !important;
    font-size: 14px;
}

.my-ftr .row {
    padding: 9px 0px;
}






/*login and sign up */

.main-panel__content {
    width: 100%;
    max-width: 400px;
    text-align: center;
    clear: both;
    /*box-shadow: 0px 0px 13px #0c0c0c7a;*/
    margin-left: auto;
    margin-right: auto;
    /*padding: 20px 24px;*/
    /*background: #fff;*/
}


.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

#login {
    text-align: center;
}


.main-panel__title {
    font-size: 30px;
    color: #323945;
    margin: 0 0 14px;
}


.frmhr {
    width: 100px;
    text-align: left;
    border: 1px solid #044b7c;
    margin-bottom: 12px;
}
.sgnup .frmhr {
    margin-top: 3px;
    margin-bottom: 10px;
}

.sgnup .pf-form__textfield__input {    padding-top: 5px; padding-bottom:5px;
}

.pf-form__message {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.67;
}
.pf-form__message.pf-form__message--error {position:absolute;right:2px;}


.pf-form__textfield {
    margin-bottom: 20px;
    position: relative;
    text-align: right;
}


.pf-form__textfield__input {
    box-sizing: border-box;
    border-radius: 3px;
    background: #f4f4f4;
    border: 1px solid #e0e6ed;
    padding:3px 12px;
    color: #5c6776;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    display: block;
    padding-left: 40px; box-shadow:0px 2px 5px #7c7c7c33; border:none;height:38px;
}


.pf-form__textfield__input {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
}


.pf-form__textfield i {
    float: left;
    width: 18px;
    position: absolute;
    top: 45px;
    left: 10px;
}


    .pf-form__textfield i img {
        width: 100%;
    }

.lgnbtn {
    color: #fff;
    font-size: 1.2rem;
    height: 45px;
    font-weight: 500;
    /* background-color: #ef3b13; */
    /*box-shadow: 0px 9px 15px #58930b4a;*/
    border: none;
    border-radius: 28px;
    /*background-image: linear-gradient(to right, #2bb3f4, #3f64fa);*/
}




.frgt {
    color: #a5a5a5 !important;
    text-decoration: none !important;
    font-size: 14px;
    margin-top: 12px;
    position: relative;
    top: 6px;
}

.signupbtn {
    color: #fff !important;
    text-decoration: none !important;
    font-weight: 400;
    background-color: #0000004d;
    padding: 2px 10px 3px;
    border-radius: 20px;
}


.pf-copyright {
    color: #fff; font-size:13px;
}

    .pf-copyright a {
        margin-left: 5px;
    }

.frmlgn {  z-index: 22; position: relative;}
.frmlgn .pf-form__textfield__input{color:#000}




.vdo2.bnr-back-video .back-video {
    z-index: 4;
}

.vdo2 .bnr-overly {
    z-index: 4;
    inset: 0px;
    background: #00000054;
    position: absolute;
    height: 100%;
}

.vdo2.bnr-back-video .back-video video {
    top: 44%;
    transform: translate(-50%, -53%);
}

.vdo2 {
    height: 100vh;
}



.toggle-password.fa-icon {
    position: absolute;
    right: 12px;
    top: 45%;
    color: #e7e7e7;
}

:focus {
    outline: none!important;
}

input:-webkit-autofill {
    background-image: none!important;
}


.page-section.signup { padding: 2rem 0;}

    .page-section.signup .pf-form__textfield {  margin-bottom: 14px; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
}



.bgvdolgn.page-section .bnr-back-video {
    overflow: hidden;
}
.bgvdolgn.page-section .vdo2.bnr-back-video .back-video video {
    top: 45%;
}

.bg-gry{background:#f7f7f7;}
.frmlgn .main-panel__title{font-size: 25px;color: #fff; margin-bottom: 2px;font-weight:600;}
.frmlgn .frmhr{margin-top:0px;width:30px; margin-bottom:30px;border:none;}
.frmlgn form {margin-top:2px; }
.frmlgn .main-panel__content{border-radius:10px;max-width:initial;}
.frmlgn.sgnup form {margin-top:6%;}
.frmlgn.sgnup .main-panel__title {font-size: 24px;}

.formBG {
    /* background-image:url(); */
    background-position: center center;height:100vh;}
.formBG.signup {
    /* background-image:url(); */
    background-position: center center;height:100vh;}
.formBG.userDtls {
    /* background-image:url(); */
    background-position: center center;height:100vh;}
.formBG .pf-form__textfield__input {background:#ececec !important;box-shadow:none;border:2px solid #6f7176;color:#1e3d62;padding-left:12px;color: #1b1d1d !important;}
.formBG .pf-form__textfield {text-align:left;}
.formBG .pf-form__textfield label, .formBG .form-check label {color:#fff;font-size:16px;margin-bottom:2px;}
.formBG a {color:#fff;font-size: 13px;text-decoration:none;}
.formBG a:hover {color:#ffeae5;text-decoration:none;}
.formBG a.navbar-brand img {height: 30px !important;}
.formBG .pf-form__textfield i {display:none;} 
.formBG .sgnup .pf-form__textfield__input {padding-top: 5px;padding-bottom: 7px;}
.formBG .toggle-password.fa-icon {top:36px;}
.formBG ::-webkit-input-placeholder { /* Edge */color: #9e9e9e;}
.formBG :-ms-input-placeholder { /* Internet Explorer 10-11 */color: #9e9e9e;}
.formBG ::placeholder { color: #9e9e9e;}
.formBG .captcha {display:inline-block; padding-left: 70px;}
/*.dobImg .ui-datepicker-trigger {display:none;}*/
.dobImg {position:relative;}
.dobImg img.ui-datepicker-trigger {position:absolute;right: 10px;top: 35px;cursor:pointer;}
.ui-datepicker .ui-datepicker-header {background-color: #ececec;border-color:#333;}
.ui-widget.ui-widget-content { background-color: #ececec;border: 1px solid #333;color: #333;}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {background-color: #fff;color: #333;}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {border: 1px solid #333;background: #333;font-weight: normal; color: #ffffff;}

.rightWraps {background-image:linear-gradient(224deg, #6f7176, #e5e5e5,#e5e5e5, #6f7176);height:100vh;position:relative;}
.rightWraps .logo {height:30px;margin:5px 0;}
.ctrRight {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);text-align:center; width: 80%;}
/*.scTxt {color:#fff;font-size:20px;margin-top:15px;}*/
.scTxt {background-color: #fff;color: #1b1d1d;font-size: 16px;margin-top: 10px;font-weight: 800;padding: 5px;border-radius: 3px;}
.title-mar {margin-bottom:50px;}
.socialLogIn {margin-top:3px;}
.socialLogIn a, .socialLogIn a:hover {padding:7px 20px;font-size:16px;background-color:#FFF;color:#333;border-radius:25px;text-decoration: none;margin: 2px 2px;width: 47%;display: inline-block;box-shadow: 1px 1px 5px #b1b1b1;}
.socialLogIn a:hover {box-shadow: 1px 1px 5px #383838;}
.socialLogIn .fbLogin {background-color:#395196;color:#fff;}
.socialLogIn .fbLogin:hover {background-color:#fff;color:#395196;}
.socialLogIn .glogin {background-color:#FFF;color:#333;}
.socialLogIn .glogin:hover {background-color:#121212;color:#488bf4;}
.socialLogIn a i {font-size:18px;margin-right:5px;}
.socialLogIn .glogin img {height:18px;margin-top: -3px;margin-right: 5px;}
.logoN {height:30px;margin: 5px 0;}


